import { default as document_45drivenmX3P0ZuwlnMeta } from "/opt/build/repo/node_modules/@nuxt/content/dist/runtime/pages/document-driven.vue?macro=true";
import { default as _91_46_46_46slug_93AVv1QELryvMeta } from "/opt/build/repo/pages/about/[...slug].vue?macro=true";
import { default as indexif8xBg25EOMeta } from "/opt/build/repo/pages/about/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93IXkte3IESlMeta } from "/opt/build/repo/pages/spaces/[...slug].vue?macro=true";
import { default as indexz94RcOatHHMeta } from "/opt/build/repo/pages/spaces/index.vue?macro=true";
import { default as _91_46_46_46slug_93RJKFSIsrOgMeta } from "/opt/build/repo/pages/words/[...slug].vue?macro=true";
import { default as indexFlNo7ThQJYMeta } from "/opt/build/repo/pages/words/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/pages/document-driven.vue")
  },
  {
    name: "about-slug",
    path: "/about/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/about/[...slug].vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/build/repo/pages/about/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "spaces-slug",
    path: "/spaces/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/spaces/[...slug].vue")
  },
  {
    name: "spaces",
    path: "/spaces",
    component: () => import("/opt/build/repo/pages/spaces/index.vue")
  },
  {
    name: "words-slug",
    path: "/words/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/words/[...slug].vue")
  },
  {
    name: "words",
    path: "/words",
    component: () => import("/opt/build/repo/pages/words/index.vue")
  }
]